import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MainComponent from "./components/MainComponent";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/:id" element={<MainComponent />} />
      </Routes>
    </Router>
  );
}
export default App;
