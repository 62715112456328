import { useState, useEffect } from "react";
import axios from "axios";
import CryptoJS from "crypto-js";

const useFetchData = (id) => {
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [falsePassword, setFalsePassword] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/${id}`);
      setData(response.data);
      setLoading(false);
      if (
        response.data.headerLogo ===
        "https://static.timelapserobot.com/assets/images/logo.png"
      ) {
        let newLogoUrl =
          "https://static.timelapserobot.com/assets/images/tlr-logo-sharing-page.png";
        setData((prevData) => ({
          ...prevData,
          headerLogo: newLogoUrl,
        }));
      }
    } catch (error) {
      if (error.response.status === 403) {
        setLoading(false);
        setIsModalOpen(true);
      } else {
        setError(error);
      }
      console.error("There was an error fetching the data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  /*const fetchData = async (passwordHash = null) => {
    try {
      const params = passwordHash ? { password: passwordHash } : {};
      const response = await axios.get(url, { params });
  */
  const sendPassword = (password) => {
    const passwordHash = CryptoJS.SHA256(password).toString();
    sessionStorage.setItem("passwordHash", passwordHash);
    sendPasswordToApi(passwordHash); // Refetch the data with the hashed password
    setIsModalOpen(false); // Close modal after submitting
  };

  const sendPasswordToApi = async (passwordHash) => {
    try {
      const response = await axios.get(`${apiUrl}/${id}`, {
        params: { password: passwordHash },
      });
      setData(response.data);
      setFalsePassword(false);
      if (
        response.data.headerLogo ===
        "https://static.timelapserobot.com/assets/images/logo.png"
      ) {
        let newLogoUrl =
          "https://static.timelapserobot.com/assets/images/tlr-logo-sharing-page.png";
        setData((prevData) => ({
          ...prevData,
          headerLogo: newLogoUrl,
        }));
      }
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        sessionStorage.removeItem("passwordHash");
        setFalsePassword(true);
        setIsModalOpen(true);
      }
    }
  };

  useEffect(() => {
    if (data?.images.length === 0) {
      setIsEmpty(true);
    }
    if (data?.headerColor === undefined || data?.headerColor !== null) {
      document.documentElement.style.setProperty(
        "--button-background-color",
        data?.headerColor
      );
    }
  }, [data]);

  return {
    loading,
    data,
    error,
    isEmpty,
    isModalOpen,
    setIsModalOpen,
    sendPassword,
    falsePassword,
  };
};
export default useFetchData;
