import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const FilterDate = ({ filterDate, setFilterDate, data, setIndex }) => {
  const [dates2] = useState([]);
  const handleDateChange = (selectedDate) => {
    //setFilterDate(event.target.value);

    //let inputDate = new Date(event.target.value);
    let inputDate = new Date(selectedDate);
    let nearestIndex = 0;
    let difference = 0;
    let minDifference = Infinity;
    data.images.forEach((image) => {
      let checkDate = new Date(image.timestamp);
      difference = Math.abs(inputDate - checkDate);
      if (difference < minDifference) {
        minDifference = difference;
        nearestIndex = data.images.indexOf(image);
      }
    });
    setIndex(nearestIndex);
    let date = new Date(data.images[nearestIndex].timestamp);
    let timeZoneOffset = date.getTimezoneOffset() * 60000;
    let adjustedTime = new Date(date.getTime() - timeZoneOffset);
    setFilterDate(adjustedTime);
  };

  /*const getHightlightDates = () => {
    const dates = new Set();
    const array = [];
    for (let i = 0; i < data.images.length; i++) {
      const currentEntryDate = moment(data.images[i].timestamp)
        .startOf("day")
        .toDate();
      //const currentEntryDate = new Date(data.images[i].timestamp);
      //const currentEntryDate = moment(data.images[i].timestamp);
      //console.log(currentEntryDate);

      if (!dates.has(currentEntryDate)) {
        array.push(currentEntryDate);
        dates.add(currentEntryDate);
      }
    }
    setDates2(array);
    return array;
  };*/

  const getDates = () => {
    const dates = new Set();
    for (let i = 0; i < data.images.length; i++) {
      const currentEntryDate = new Date(data.images[i].timestamp)
        .toISOString()
        .slice(0, 10);
      if (!dates.has(currentEntryDate)) {
        dates2.push(currentEntryDate);
        dates.add(currentEntryDate);
      }
    }
  };
  getDates();

  const isDateAvailable = (date) => {
    const formattedDate = date.toISOString().slice(0, 10); // "YYYY-MM-DD"
    return dates2.includes(formattedDate);
  };

  return (
    <div className="col-lg-2 col-4">
      <div className="text-center text-lg-left mb-2 mb-lg-0">
        <DatePicker
          selected={filterDate}
          id="filterInput"
          onChange={handleDateChange}
          showTimeSelect
          dateFormat="dd.MM.yyyy HH:mm"
          timeFormat="HH:mm"
          timeIntervals={1}
          //highlightDates={getHightlightDates()}
          filterDate={isDateAvailable}
          placeholderText="Select a date and time"
        ></DatePicker>
        {/*<input
          id="filterInput"
          type="datetime-local"
          value={filterDate}
          onChange={handleDateChange}
        ></input>*/}
      </div>
    </div>
  );
};

export default FilterDate;
