import React from "react";
import Icon from "@mdi/react";
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";

const PrevAndNextButtons = ({ goToPreviousImage, goToNextImage }) => {
  return (
    <div className="col-lg-2 col-4 text-lg-right text-right">
      <button
        onClick={goToPreviousImage}
        className="previousImage btn-primary"
        style={{ marginRight: "5px" }}
      >
        <Icon path={mdiChevronLeft} size={1.4}></Icon>
      </button>
      <button
        onClick={goToNextImage}
        className="nextImage btn-primary"
        style={{ marginRight: "1px" }}
      >
        <Icon path={mdiChevronRight} size={1.4}></Icon>
      </button>
    </div>
  );
};
export default PrevAndNextButtons;
