import React from "react";
import moment from "moment";

const DropdownFilteringMenu = ({
  selected,
  setSelected,
  data,
  setFilteredIndexNumbers,
}) => {
  const handleSelect = (event) => {
    setSelected(event.target.textContent);
    const indexList = [];
    if (event.target.textContent === "All Images") {
      for (let i = 0; i < data.images.length; i++) {
        indexList.push(i);
      }
    } else if (event.target.textContent === "Day") {
      const seenDates = new Set();
      let imagesForOneDay = [];
      for (let i = 0; i < data.images.length; i++) {
        const currentEntryDate = new Date(data.images[i].timestamp)
          .toISOString()
          .split("T")[0];
        imagesForOneDay.push(i);
        if (seenDates.size === 0) {
          seenDates.add(currentEntryDate);
        }
        if (!seenDates.has(currentEntryDate)) {
          seenDates.add(currentEntryDate);
          let middleOfTheDayIndexList = imagesForOneDay.length / 2;
          if (middleOfTheDayIndexList % 1 !== 0) {
            middleOfTheDayIndexList = Math.floor(middleOfTheDayIndexList);
          }
          indexList.push(imagesForOneDay[middleOfTheDayIndexList]);
          imagesForOneDay = [];
        }
        /*if (!seenDates.has(currentEntryDate)) {
          seenDates.add(currentEntryDate);
          indexList.push(i);
        }*/
      }
    } else if (event.target.textContent === "Week") {
      const seenWeeks = new Set();
      for (let i = 0; i < data.images.length; i++) {
        const date = moment(data.images[i].timestamp);
        const yearWeek = `${date.year()}-W${date.isoWeek()}`;
        const dayOfWeek = date.format("DD-MM-yyyy");
        if (!seenWeeks.has(yearWeek)) {
          seenWeeks.add(yearWeek);
          const dayIndexList = [];
          for (let index = 0; index < data.images.length; index++) {
            const date = moment(data.images[index].timestamp);
            if (date.format("DD-MM-yyyy") === dayOfWeek) {
              dayIndexList.push(index);
            }
          }
          let middleOfDayIndex = dayIndexList.length / 2;
          if (middleOfDayIndex % 1 !== 0) {
            middleOfDayIndex = Math.floor(middleOfDayIndex);
          }
          indexList.push(dayIndexList[middleOfDayIndex]);
        }
      }
    } else if (event.target.textContent === "Month") {
      const seenMonths = new Set();
      for (let i = 0; i < data.images.length; i++) {
        const date = moment(data.images[i].timestamp);
        const dateMonth = date.format("YYYY-MM");
        const dayOfWeek = date.format("DD-MM-yyyy");
        if (!seenMonths.has(dateMonth)) {
          seenMonths.add(dateMonth);
          const dayIndexList = [];
          for (let index = 0; index < data.images.length; index++) {
            const date = moment(data.images[index].timestamp);
            if (date.format("DD-MM-yyyy") === dayOfWeek) {
              dayIndexList.push(index);
            }
          }
          let middleOfDayIndex = dayIndexList.length / 2;
          if (middleOfDayIndex % 1 !== 0) {
            middleOfDayIndex = Math.floor(middleOfDayIndex);
          }
          indexList.push(dayIndexList[middleOfDayIndex]);
        }
      }
    }
    setFilteredIndexNumbers(indexList);
  };

  return (
    <div className="col-lg-2 col-4 text-lg-center mb-1 text-right">
      <button
        className="btn btn-primary dropdown-toggle"
        type="button"
        id="dropdownMenuButton"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {selected}
      </button>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <li>
          <button
            className="dropdown-item"
            value="All Images"
            onClick={handleSelect}
          >
            All Images
          </button>
        </li>
        <li>
          <button className="dropdown-item" value="day" onClick={handleSelect}>
            Day
          </button>
        </li>
        <li>
          <button className="dropdown-item" value="week" onClick={handleSelect}>
            Week
          </button>
        </li>
        <li>
          <button
            className="dropdown-item"
            value="month"
            onClick={handleSelect}
          >
            Month
          </button>
        </li>
      </ul>
    </div>
  );
};
export default DropdownFilteringMenu;
