import React from "react";
import Icon from "@mdi/react";
import { mdiPlay, mdiStop } from "@mdi/js";

const PlayButtonWithSlider = ({
  playImages,
  intervalId,
  data,
  index,
  handleSliderChange,
}) => {
  return (
    <div className="row justify-content-center">
      <div className="col-lg-7 col-12 text-center mb-5">
        <button
          id="playButton"
          onClick={playImages}
          style={{
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
          }}
        >
          <Icon path={intervalId ? mdiStop : mdiPlay} size={1} />
        </button>
        <input
          type="range"
          min="0"
          max={data.images.length - 1}
          value={index}
          onChange={handleSliderChange}
          className="slider"
          style={{
            color: "#0d97be",
          }}
        />
      </div>
    </div>
  );
};
export default PlayButtonWithSlider;
