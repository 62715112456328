import React from "react";
import styled, { keyframes } from "styled-components";

// Define keyframes for spinning animation
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// Define the styles for the container and elements
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background-color: #282c34;
`;

const Globe = styled.div`
  width: 80px;
  height: 80px;
  border: 10px solid #61dafb;
  border-top: 10px solid transparent;
  border-radius: 50%;
  animation: ${spin} 2s linear infinite;
`;

const LoadingText = styled.p`
  color: white;
  font-size: 20px;
  margin-top: 20px;
`;

const ErrorText = styled.p`
  color: red;
  font-size: 20px;
  margin-top: 20px;
`;

// The Loading component
const Loading = ({ error, isEmpty }) => {
  if (error) {
    return (
      <LoadingContainer>
        <ErrorText> Camera not found</ErrorText>
      </LoadingContainer>
    );
  } else if (isEmpty) {
    <LoadingContainer>
      <ErrorText>No Images to load</ErrorText>
    </LoadingContainer>;
  } else {
    return (
      <LoadingContainer>
        <Globe />
        <LoadingText>Loading Timelapse...</LoadingText>
      </LoadingContainer>
    );
  }
};

export default Loading;
