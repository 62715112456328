import React from "react";

const Images = ({ baseUrl, images, index }) => {
  return (
    <div className="row justify-content-center">
      <div className="col-lg-6 my-2 text-center">
        <img
          id="image"
          src={baseUrl + images[index].accessUrl}
          alt={`Slide ${index + 1}`}
          className="slider-image"
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default Images;
