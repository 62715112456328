import React from "react";

const ProjectAndCamerasCard = ({ projectName, projectCameras }) => {
  const url = process.env.REACT_APP_SHARE_URL;
  return (
    <div className="row justify-content-center">
      <div
        className="col-lg-6 text-lg-left my-2 mx-2"
        style={{ marginBottom: "10px" }}
      >
        <div className="card">
          <div className="card-body">
            <div className="col-lg-12 col-12 d-sm-block d-flex justify-content-center">
              <h1 className="mt-0 mx-1" style={{ fontSize: "20px" }}>
                {projectName}
              </h1>
            </div>
            <div className="col-lg-12 col-12 d-sm-block d-flex justify-content-center">
              {/*For TEst: `https://share-test.timelapserobot.com/${camera.shareId}` */}
              {/*For Prod: `https://share.timelapserobot.com/${camera.shareId}` */}
              {projectCameras.map((camera, index) => (
                <a
                  key={camera.shareId ?? index}
                  href={`${url}/${camera.shareId}`}
                  className="btn btn-primary mx-1 my-1 my-lg-0"
                  id="cameraLinkButtons"
                >
                  {camera.name}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectAndCamerasCard;
