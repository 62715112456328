import React from "react";

const LogoCard = ({ headerLogo }) => {
  return (
    <div className="row justify-content-center">
      <div className="col-lg-6 text-lg-left my-2 mx-lg-2 text-center">
        <img src={headerLogo} alt="Logo" style={{ maxWidth: "45%" }}></img>
      </div>
    </div>
  );
};

export default LogoCard;
